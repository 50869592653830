<!-- 等级数据新增 -->
<template>
    <div class="addRankData">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">等级数据新增</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单部分 -->
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="" inline label-width="150px">
            <el-form-item label="学科编号">
                <el-input v-model="form.number" placeholder="请输入学科编号"></el-input>
            </el-form-item>
            <el-form-item label="等级名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入等级名称"></el-input>
            </el-form-item>
            <el-form-item label="简称">
                <el-input v-model="form.abbreviation" placeholder="请输入简称"></el-input>
            </el-form-item>
            <el-form-item label="上级等级">
                <el-input v-model="form.superiorData" placeholder="请输入上级等级"></el-input>
            </el-form-item>
            <el-form-item label="等级">
                <el-input v-model="form.grade" placeholder="请输入等级(1-9)"></el-input>
                <!-- <el-select v-model="form.grade" placeholder="请选择等级" :clearable="true">
                    <el-option v-for="item in form.gradeChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select> -->
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.condition" placeholder="请选择状态" :clearable="true">
                    <el-option v-for="item in form.stateChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="收费标准" prop="standard">
                <el-input v-model="form.standard" placeholder="请输入收费标准"></el-input>
                <span class="note-width" style="color:#FF0000;font-size: 14px; margin-left: 18px;">
                    注：考试等级用，包含报名费、考级费、证书费、邮寄费
                </span>
            </el-form-item>
            <el-form-item label="说明">
                <el-input type="textarea" v-model="form.explain" class="textArea-width" placeholder="请输入说明"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    number: "", //学科编号
                    name: "", //等级名称
                    abbreviation: "", //简称
                    superiorData: "", //上级等级
                    grade: "", //等级
                    gradeChoose: [{
                            label: "考试等级",
                            value: "1"
                        },
                        {
                            label: "教学等级",
                            value: "2"
                        },
                    ], //等级选项
                    condition: "1", //状态
                    stateChoose: [{
                            label: "可用",
                            value: "1"
                        },
                        {
                            label: "禁用",
                            value: "2"
                        },
                    ], //状态选择
                    standard: "", //收费标准
                    explain: "" //说明
                },
                rules: {
                    name: [{
                        required: true,
                        message: '请输入等级名称',
                        trigger: 'blur'
                    }, ],
                    standard: [{
                        required: true,
                        message: '请输入收费标准',
                        trigger: 'blur'
                    }, ],

                },
                bool: false
            }
        },
        created() {

        },
        methods: {
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        if (this.bool) {
                            return
                        }
                        this.bool = true
                        var data = this.form
                        this.$request({
                            url: '/api/level/add',
                            method: 'POST',
                            data: {
                                level_number: data.number,
                                name: data.name,
                                abbreviation: data.abbreviation,
                                pid: data.superiorData,
                                level: data.grade,
                                status: data.condition,
                                price: data.standard,
                                remark: data.explain
                            }
                        }).then(res => {
                            if (res.code == 1) {
                                this.$message({
                                    message: '新增列表成功',
                                    type: 'success'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }
                        }).catch(() => {
                            this.bool = false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                this.goBack()
                this.$refs[form].resetFields();
            }
        }
    }
</script>

<style scoped="scoped">
    .addRankData {
        width: 100%;
        height: 100%;
    }

    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }

    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }

    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }

    ::v-deep .el-form-item__label {
        color: #000000;
    }

    .el-input {
        width: 300px;
        height: 37px !important;
    }

    .el-select {
        width: 300px;
    }

    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }

    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
